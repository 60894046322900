.custom-user-location {
  background: transparent;
}

.user-marker-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.pulse {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  background: rgba(66, 133, 244, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

.marker {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}

.custom-alert-icon {
  background: transparent;
}

.alert-icon_res {
  position: absolute;
  right: 20px;
  top: 8px;
  padding: 0px;
  border-radius: 50%;
}
.vehicle-icon_2 {
  animation: bounce 1s ease-in-out infinite;
  width: 60px;
  height: 60px;
}
.alert-marker-container {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-pulse {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: alertPulse 2s infinite;
}

.exceso-de-velocidad .alert-pulse {
  background: rgba(255, 0, 0, 0.2);
}

.detenido .alert-pulse {
  background: rgba(255, 165, 0, 0.2);
}

.encendido-motor .alert-pulse {
  background: rgba(0, 255, 0, 0.2);
}

.apagado-de-motor .alert-pulse {
  background: rgba(128, 128, 128, 0.2);
}

@keyframes alertPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.vehicle-marker-container {
  position: relative;
  width: 60px;
  height: 40px;
}

.vehicle-icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
}

.exceso-de-velocidad {
  background: rgba(255, 0, 0, 0.2);
}

.detenido {
  background: rgba(255, 165, 0, 0.2);
}

.encendido-motor {
  background: rgba(0, 255, 0, 0.2);
}

.apagado-de-motor {
  background: rgba(128, 128, 128, 0.2);
}

.puerta-cerrada .alert-pulse {
  background: rgba(0, 0, 255, 0.2);
}

.puerta-abierta .alert-pulse {
  background: rgba(255, 0, 255, 0.2);
}

.acercamiento .alert-pulse {
  background: rgba(255, 255, 0, 0.2);
}

.boton-de-panico .alert-pulse {
  background: rgba(255, 0, 0, 0.4);
}

.entrada .alert-pulse {
  background: rgba(0, 255, 0, 0.2);
}

.salida .alert-pulse {
  background: rgba(255, 165, 0, 0.2);
}

/**/

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -90%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.alert-container {
  position: relative;
  padding-bottom: 5px; /* Espacio para la flecha */
}
.vehicle-icon_3 {
  animation: bounce 1s ease-in-out infinite;
}
.vehicle-icon_2 {
  animation: bounce 1s ease-in-out infinite;
  width: 32px;
  height: 32px;
}
.alert-icons2 {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
}
.popup-card {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px; /* Espacio para la flecha */
}
/* Triángulo principal */
.popup-card:after {
  content: "";
  position: absolute;
  bottom: -15px; /* Ajustado para hacer la flecha más visible */
  left: 50%;
  transform: translateX(-50%);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid white;
}
/* Sombra del triángulo */
.popup-card:before {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.marker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-container {
  background: transparent;
  padding: 0px 0px;
  border-radius: 3px;
  border: 0px solid #ccc;
  margin-top: -8px;
  white-space: nowrap;
}

.label-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
