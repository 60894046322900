.alert-icons2 {
    display: flex; 
    align-items: center !important;  
    justify-content: center !important;
    position: relative;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, -90%); }
    to { opacity: 1; transform: translate(-50%, -100%); }
  }
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
  .alert-container {
    position: relative;
    padding-bottom: 15px; /* Espacio para la flecha */              
  }
  .vehicle-icon_2 {
    animation: bounce 1s ease-in-out infinite;
  }
 
  .popup-card {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    margin-bottom: 15px; /* Espacio para la flecha */
  }
  /* Triángulo principal */
  .popup-card:after {
    content: '';
    position: absolute;
    bottom: -15px; /* Ajustado para hacer la flecha más visible */
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid white;
  }
  /* Sombra del triángulo */
  .popup-card:before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid rgba(0,0,0,0.1);
    z-index: -1;
  }


 /* Estilos para marcadores personalizados */
.custom-marker {
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transform: translate(-50%, -50%);
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;
}

.custom-marker img {
  width: 32px;
  height: 32px;
}

/* Estilos para los clusters */
.marker-cluster {
  cursor: pointer;
  position: relative;
  z-index: 100;
}

.marker-cluster-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(66, 133, 244, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border: 2px solid white;
  transform: translate(-50%, -50%);
  will-change: transform;
  backface-visibility: hidden;
  transition: transform 0.2s ease-out, background-color 0.2s ease-out;
}

.marker-cluster-circle:hover {
  transform: translate(-50%, -50%) scale(1.05);
  background-color: rgba(25, 118, 210, 0.9);
}

.marker-cluster-text {
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

/* Animaciones para clusters */
@keyframes clusterAppear {
  from {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.6;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.marker-cluster-circle {
  animation: clusterAppear 0.3s ease-out forwards;
}

/* Variaciones de colores para diferentes tamaños de clusters */
.marker-cluster[data-count="2"] .marker-cluster-circle,
.marker-cluster[data-count="3"] .marker-cluster-circle,
.marker-cluster[data-count="4"] .marker-cluster-circle {
  background-color: rgba(66, 133, 244, 0.85);
}

.marker-cluster[data-count="5"] .marker-cluster-circle,
.marker-cluster[data-count="6"] .marker-cluster-circle,
.marker-cluster[data-count="7"] .marker-cluster-circle,
.marker-cluster[data-count="8"] .marker-cluster-circle,
.marker-cluster[data-count="9"] .marker-cluster-circle {
  background-color: rgba(219, 68, 55, 0.85);
}

.marker-cluster[data-count="10"] .marker-cluster-circle,
.marker-cluster[data-count="20"] .marker-cluster-circle,
.marker-cluster[data-count="30"] .marker-cluster-circle,
.marker-cluster[data-count="40"] .marker-cluster-circle {
  background-color: rgba(244, 160, 0, 0.85);
}

.marker-cluster[data-count="50"] .marker-cluster-circle,
.marker-cluster[data-count="100"] .marker-cluster-circle {
  background-color: rgba(15, 157, 88, 0.85);
}

.traffic-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.traffic-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
}

.traffic-button.active {
  background-color: #4285F4;
  color: white;
}